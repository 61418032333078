import React from "react";
import "./Clock.css";

interface IProps {}
interface IState {
  timeString: string;
}

export default class Clock extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      timeString: "loading",
    };
    setInterval(() => {
      this.setState({ timeString: new Date().toUTCString() });
      this.render();
    }, 10);
  }

  render() {
    return <div className="Clock">{this.state.timeString}</div>;
  }
}
